<template>
  <!-- Used for inline date and time inputs on FilterControls -->
  <b-input-group class="range-input-wrapper">
    <single-input
      :id="ids[0]"
      :label="labels[0]"
      :type="type"
      :placeholder="placeholders[0]"
      :state="states[0]"
      :feedback="feedbacks[0]"
      :value="values[ids[0]]"
      @input-change="handleInput"
    >
    </single-input>
    <single-input
      :id="ids[1]"
      :label="labels[1]"
      :type="type"
      :placeholder="placeholders[1]"
      :state="states[1]"
      :feedback="feedbacks[1]"
      :value="values[ids[1]]"
      @input-change="handleInput"
    />
  </b-input-group>
</template>

<script>
import SingleInput from './SingleInput'

export default {
  name: 'range-input',
  components: {
    SingleInput
  },
  props: {
    /**
     * Array of length 2.
     * Contains the id of each single-input.
     */
    ids: {
      type: Array,
      required: true
    },
    /**
     * Array of length 2.
     * Contains the label of each single-input.
     */
    labels: {
      type: Array,
      required: true
    },
    /**
     * Array of length 2.
     * Contains the placeholder of each single-input.
     */
    placeholders: {
      type: Array,
      required: true
    },
    /**
     * Contains the values of each single-input.
     */
    values: {
      type: Object,
      required: true
    },
    /**
     * The type of the range-input.
     */
    type: {
      type: String,
      default: 'text'
    },
    /**
     * The type of the values. i.e datetime
     */
    valueId: {
      type: String,
      default: 'text'
    },
    /**
     * The validation states for range-input
     */
    states: {
      type: Array,
      required: true
    },
    /**
     * The validation's invalid feedbacks for range-input
     */
    feedbacks: {
      type: Array,
      required: true
    }
  },
  mounted () {

  },
  methods: {
    /**
     * @public
     * Construct new input value and emit input-change event with the new value.
     * @param {String} id The field that needs to be updated. For example, 'date\_gte' or 'date\_lte'
     * @param {String} value The updated value of the field
     * @returns {None}
     */
    handleInput (id, value) {
      const newData = { ...this.values, [id]: value }
      /**
       * Event that indicates a change in input value.
       * @property {String} type The type of input. Used to indicate the field to be updated. For example, date or time.
       * @property {Object} newData The updated value of the field
       */
      this.$emit('input-change', this.valueId, newData)
    }
  }
}
</script>
