<template>
  <b-col  sm="12" lg="6" class="input-wrapper">
    <div class="label-wrapper">
      <label :for="id" class="label">
        <h3>{{ label }}</h3>
      </label>
      <slot />
    </div>
    <b-form-input
      v-if="!multiselect"
      :type="type"
      :placeholder="placeholder"
      :disabled="disabled"
      :value="value"
      :state="state"
      @input="handleChange"
    >
    </b-form-input>
    <multiselect
      v-else
      class="custom-multiselect"
      :options="options"
      :placeholder="placeholder"
      :closeOnSelect="false"
      :taggable="false"
      :multiple="true"
      :value="value"
      :selectLabel="$LOCAL('COMMON_WORD').SELECT"
      :deselectLabel="$LOCAL('COMMON_WORD').REMOVE"
      @input="handleChange"
    >
      <span slot="noResult" class="message">{{ $AUDIT('SINGLE_INPUT').MULTISELECT.NO_SEARCH_RESULT }} </span>
    </multiselect>
    <b-form-invalid-feedback id="input-live-feedback">
      {{ feedback }}
    </b-form-invalid-feedback>
  </b-col>
</template>

<script>
import multiselect from 'vue-multiselect'

export default {
  name: 'single-input',
  components: {
    multiselect
  },
  props: {
    /**
     * The input label.
     */
    label: {
      type: String
    },
    /**
     * The input placeholder.
     */
    placeholder: {
      type: String,
      required: true
    },
    /**
     * The input type.
     */
    type: {
      type: String,
      required: true
    },
    /**
     * The input id.
     */
    id: {
      type: String,
      required: true
    },
    /**
     * The input value.
     * If multiselect input, the value is an array. Else, a string value.
     */
    value: {
      type: [Array, String],
      required: true
    },
    /**
     * The state of the input. Enabled (false) or disabled (true).
     */
    disabled: {
      type: Boolean,
      default: false
    },
    /**
     * Indicates if the input is of type multiselect.
     */
    multiselect: {
      type: Boolean,
      default: false
    },
    /**
     * Options array for a multiselect input. Not needed if not a multiselect input.
     */
    options: {
      type: Array,
      default: () => []
    },
    state: {
      type: Boolean,
      default: null
    },
    feedback: {
      type: String
    }
  },
  data: () => ({

  }),
  computed: {

  },
  mounted () {

  },
  methods: {
    /**
     * @public
     * Emit input-change event whenever there is a change in input value.
     * @param {String | Array} value The update value of the input
     * @returns {None}
     */
    handleChange (value) {
      /**
       * Event that indicates a change in input value.
       * @property {String} id The id of input. Used to indicate the field to be updated
       * @property {String | Array} value The updated value of the field
       */
      this.$emit('input-change', this.id, value)
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.input-wrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .label {
    h3 {
      font-weight: bold;
      font-size: 1.3rem;
      padding: 0;
      margin: 0;
    }
  }
}

.custom-multiselect::v-deep {
  .multiselect__tags {
    max-height: 300px;
    overflow-y: auto;
    border: 1px solid #ced4da;
    position: relative;
  }

  .multiselect__input {
    margin: 0;
    padding: 0.15rem 0;
  }

  .multiselect__placeholder, .multiselect__input::placeholder {
    padding: 0;
    margin: 0;
    color: #495057;
    font-size: 1rem;
  }

  .multiselect__tags {
    padding: 0.375rem 0.75rem;
  }

  .multiselect__tag {
    margin-bottom: 0;
    margin-top: 5px;
  }

  .message {
    font-style: italic;
    font-size: 1rem;
    opacity: 0.8;
  }

}
</style>
