<template>
  <div class="filter-controls">
    <!-- Sorting doesn't do anything for now -->
    <search-sort-controls
      :loading="isLoading"
      :showSortAndRefresh="isSearchExecuted"
      :hasSearchBar="false"
      :hasClearSearchCross="false"
      :hasAdvancedSearch="false"
      :hasModelSelection="false"
      :currentSentSearch="''"
      :searchCount="totalNumberOfResults"
      :searchNoun="$LOCAL('COMMON_WORD').RESULTS"
      :sortFields="{}"
      :isForEntityOrLink="false"
      :isShowingSearchMessage="isSearchExecuted"
      @refresh="refresh"
    >
      <template v-slot:additional-filter-button-toggle>
        <b-btn
          :variant="isFilterExpanded ? 'success' : 'secondary'"
          class="filter-toggler"
          @click="toggleFilter"
        >
          <b-spinner v-if="isLoading" small label="Loading..."></b-spinner>
          <span v-else>{{ $AUDIT('FILTER_CONTROLS').BUTTON_TEXT.SEARCH_FILTER }}</span>
        </b-btn>
      </template>

      <template v-slot:additional-filter>
        <b-card v-if="isFilterExpanded" class="filter-form">
          <b-row>
            <!-- range-input for range inputs -->
            <range-input
              class="range-input"
              v-for="rangeInput in rangeInputs"
              :key="rangeInput.key"
              :ids="rangeInput.ids"
              :labels="rangeInput.labels"
              :type="rangeInput.type"
              :placeholders="rangeInput.placeholders"
              :values="values[rangeInput.valueId]"
              :states="rangeInput.states"
              :feedbacks="rangeInput.feedbacks"
              :valueId="rangeInput.valueId"
              @input-change="handleInputChange"
            />

            <!-- single-input for single inputs -->
            <single-input
              class="single-input"
              v-for="singleInput in singleInputs"
              :key="singleInput.key"
              :id="singleInput.id"
              :label="singleInput.label"
              :type="singleInput.type"
              :placeholder="singleInput.placeholder"
              :multiselect="singleInput.multiselect"
              :options="singleInput.options"
              :value="values[singleInput.id]"
              @input-change="handleInputChange"
            />
          </b-row>
          <div class="button-group">
            <b-button variant="outline-danger" @click="reset" :disabled="isLoading">
              {{ $LOCAL('COMMON_WORD').RESET }}
            </b-button>
            <b-button variant="primary" @click="submit" :disabled="isLoading">
              <b-spinner v-if="isLoading" small label="Loading..."></b-spinner>
              <span v-else>{{ $LOCAL('COMMON_WORD').SEARCH }}</span>
            </b-button>
          </div>
        </b-card>
      </template>
    </search-sort-controls>
  </div>
</template>

<script>
import RangeInput from '../components/RangeInput'
import SingleInput from '../components/SingleInput'
import { mapState } from 'vuex'
import SearchSortControls from '@/modules/insight/components/search_sort/SearchSortControls'
import moment from 'moment'

export default {
  name: 'filter-controls',
  components: {
    SearchSortControls,
    SingleInput,
    RangeInput
  },
  props: {
    /**
     * The previously executed filter.
     */
    lastExecutedFilter: {
      type: Object,
      default: () => {}
    },
    /**
     * The total number of filter results returned by the backend.
     */
    totalNumberOfResults: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      /**
       * The default value for datetime_gte. Set in mounted()
       */
      datetimeGTEDefault: '',
      /**
       * Check if search button is already clicked. Used to render Export, sort and refresh button.
       */
      isSearchExecuted: false,
      /**
       * Check if FilterControls is expanded.
       */
      isFilterExpanded: true,
      /**
       * The inputs configuration on the FilterControls form.
       * Note the difference in fields between 'range' and 'single' inputs.
       */
      inputs: [
        {
          key: 0,
          variant: 'range',
          labels: [
            this.$AUDIT('FILTER_CONTROLS').INPUT.DATETIME.PLACEHOLDER_1,
            this.$AUDIT('FILTER_CONTROLS').INPUT.DATETIME.PLACEHOLDER_2
          ],
          placeholders: [
            this.$AUDIT('FILTER_CONTROLS').INPUT.DATETIME.PLACEHOLDER_1,
            this.$AUDIT('FILTER_CONTROLS').INPUT.DATETIME.PLACEHOLDER_2
          ],
          states: [null, null],
          feedbacks: [
            this.$AUDIT('FILTER_CONTROLS').INPUT.VALIDATION_FEEDBACK.DATETIME_GTE_MANDATORY,
            this.$AUDIT('FILTER_CONTROLS').INPUT.VALIDATION_FEEDBACK.DATETIME_LTE_INVALID
          ],
          type: 'datetime-local',
          valueId: 'datetime',
          ids: ['datetime_gte', 'datetime_lte']
        },
        {
          key: 1,
          variant: 'single',
          multiselect: true,
          label: this.$AUDIT('FILTER_CONTROLS').INPUT.USER.LABEL,
          placeholder: this.$AUDIT('FILTER_CONTROLS').INPUT.USER.PLACEHOLDER,
          type: 'text',
          id: 'user',
          options: this.$store.state.audit.users
        },
        {
          key: 2,
          variant: 'single',
          multiselect: false,
          label: this.$AUDIT('FILTER_CONTROLS').INPUT.RECORD_ID.LABEL,
          placeholder: this.$AUDIT('FILTER_CONTROLS').INPUT.RECORD_ID.PLACEHOLDER,
          type: 'text',
          id: 'record_id'
        },
        {
          key: 3,
          variant: 'single',
          multiselect: true,
          label: this.$AUDIT('FILTER_CONTROLS').INPUT.RECORD_TYPE.LABEL,
          placeholder: this.$AUDIT('FILTER_CONTROLS').INPUT.RECORD_TYPE.PLACEHOLDER,
          type: 'text',
          id: 'record_type',
          options: this.$store.state.audit.recordTypes
        },
        {
          key: 4,
          variant: 'single',
          multiselect: false,
          label: this.$AUDIT('FILTER_CONTROLS').INPUT.DETAIL.LABEL,
          placeholder: this.$AUDIT('FILTER_CONTROLS').INPUT.DETAIL.PLACEHOLDER,
          type: 'text',
          id: 'detail'
        },
        {
          key: 5,
          variant: 'single',
          multiselect: true,
          label: this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.LABEL,
          placeholder: this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.PLACEHOLDER,
          type: 'text',
          id: 'action',
          options: [
            /**
             * The audit actions that are supported
             */
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.CREATE,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.READ,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.UPDATE,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.DELETE,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.RUN,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.SEARCH,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.LOG_IN,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.LOG_OUT,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.ADD_RELATIONS,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.REMOVE_RELATIONS,
            this.$AUDIT('FILTER_CONTROLS').INPUT.ACTION.OPTIONS.VERIFY_OTP
          ]
        }
      ],
      values: {
        datetime: { datetime_gte: '', datetime_lte: '' },
        user: [],
        record_id: '',
        record_type: [],
        detail: '',
        action: []
      }
    }
  },
  watch: {
    'values.datetime': {
      handler (changedValue) {
        // Handler to validate From Datetime and To Datetime
        if (!changedValue.datetime_gte) {
          // Set state of From Datetime to invalid because it is mandatory field but value is blank
          this.inputs[0].states = [false, null]
        } else if (changedValue.datetime_lte && (changedValue.datetime_gte > changedValue.datetime_lte)) {
          // Set state of To Datetime to invalid because To Datetime should not be before From Datetime
          this.inputs[0].states = [null, false]
        } else {
          // Reset state of From Datetime and To Datetime
          this.inputs[0].states = [null, null]
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState('audit', {
      isLoading: 'isLoading',
      error: 'error'
    }),
    /**
     * Returns the inputs that are range inputs (have to and from inputs).
     * @param {None}
     * @returns {Array} An array of objects that are range inputs
     */
    rangeInputs () {
      return this.inputs.filter(input => input.variant === 'range')
    },
    /**
     * Returns the inputs that are single inputs (have one input).
     * @param {None}
     * @returns {Array} An array of objects that are single inputs
     */
    singleInputs () {
      return this.inputs.filter(input => input.variant === 'single')
    }
  },
  mounted () {
    const period = this.$AUDIT('FILTER_CONTROLS').INPUT.DATETIME_GTE_DEFAULT.SUBTRACT_PERIOD
    const periodType = this.$AUDIT('FILTER_CONTROLS').INPUT.DATETIME_GTE_DEFAULT.SUBTRACT_PERIOD_TYPE
    this.datetimeGTEDefault = moment().subtract(period, periodType).format('YYYY-MM-DDTHH:mm')
    this.values.datetime.datetime_gte = this.datetimeGTEDefault
  },
  methods: {
    /**
     * @public
     * Open or close the FilterControl form.
     * @param {None}
     * @returns {None}
     */
    toggleFilter () {
      this.isFilterExpanded = !this.isFilterExpanded
    },
    /**
     * @public
     * Handle changes in input values.
     * @param {String} id Field of the values object. For example, date, time or user
     * @param {String | Array | Object} newValue The updated values of corresponding input id
     * @returns {None}
     */
    handleInputChange (id, newValue) {
      this.values[id] = newValue
    },
    /**
     * @public
     * Reset the input values of the FilterControls form.
     * @param {None}
     * @returns {None}
     */
    reset () {
      this.values = {
        datetime: { datetime_gte: this.datetimeGTEDefault, datetime_lte: '' },
        user: [],
        record_id: '',
        record_type: [],
        detail: '',
        action: []
      }
    },
    /**
     * @public
     * Emit an event that executes a search with the given filter values.
     * @param {None}
     * @returns {None}
     */
    submit () {
      // Check that From Datetime and To Datetime is valid
      if (!this.inputs[0].states.includes(false)) {
        this.isFilterExpanded = false
        this.isSearchExecuted = true
        this.scrollToTop()
        const filterValues = Object.assign({}, this.values)
        /**
         * Event that indicates a search is executed with a given set of filters.
         * @property {Object} filterValues The filter values
         */
        this.$emit('search-executed', filterValues)
      }
    },
    /**
     * Re-execute the last executed filter when the refresh button is clicked.
     * @param {None}
     * @returns {None}
     */
    refresh () {
      this.values = Object.assign({}, this.lastExecutedFilter)
      /**
       * Event that indicates a refresh action is being executed by user.
       * @property {None}
       */
      this.$emit('refresh')
    },
    /**
     * Scroll to the top of the page. Triggered when a filter is executed.
     * @param {None}
     * @returns {None}
     */
    scrollToTop () {
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-controls {
  text-align: left;
  padding: 0 12px 0 14px;

  .filter-toggler {
    width: 8rem;
  }

  .filter-form {
    margin-top: 15px;
    padding: 1.5rem;
  }

  .range-input {
    margin-bottom: 1.3rem;
  }

  .single-input {
    margin-bottom: 1.3rem;

    &:nth-last-child() {
      margin-bottom: 0rem;
    }
  }

  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;

    .btn {
      width: 5rem !important;
    }
  }
}
</style>
