<template>
  <b-modal
    :visible="showAuditLog"
    @hide="closeAuditLog"
    @ok="closeAuditLog"
    ok-only
    scrollable
    size="lg"
  >

    <div v-if="isLoading" class="text-center">
      <b-spinner></b-spinner>
    </div>

    <!-- Error handler component from Insight module -->
    <error-handler
      v-if="modalError !== false && !isLoading"
      :error="modalError"
      :showError="modalError !== false"
      variant="danger"
      @dismiss-error="clearModalError"
    />

    <template v-if="modalError === false && !isLoading" v-slot:modal-title>
      {{ auditLogTitle }}
    </template>

    <div v-if="modalError === false && !isLoading">
      <!-- Details -->
      <b-table class="detail" :items="[{ details: this.activeAuditLog.details }]" :hover="true"></b-table>
      <!-- Changes -->
      <b-table :items="activeAuditLog.changes" :hover="true">
        <template #thead-top>
          <b-tr>
            <b-th colspan="3">{{ $AUDIT('AUDIT_LOG').HEADER.CHANGES }}</b-th>
          </b-tr>
        </template>
      </b-table>
    </div>

  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import RecordTitleMixin from '../mixins/RecordTitleMixin'
import ErrorHandler from '../../insight/components/ErrorHandler'

export default {
  name: 'audit-log',
  components: {
    ErrorHandler
  },
  mixins: [RecordTitleMixin],
  props: {
    /**
     * The Id of the audit log viewed by user.
     */
    activeAuditLogId: {
      type: [Number, String],
      required: true
    },
    /**
     * Indicates if a specific audit log is being viewed by user.
     */
    showAuditLog: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isLoading: false,
      activeAuditLog: {} // Stores the audit log being viewed by user.
    }
  },
  /**
   * Retrive data of audit log on mount.
   */
  async mounted () {
    this.isLoading = true
    await this.retrieveAuditLog()
    this.isLoading = false
  },
  computed: {
    ...mapState('audit', {
      modalError: 'modalError', // Note that this component handles modalError instead of error.
      auditLogCache: 'auditLogCache'
    }),
    /**
     * Returns the title of the audit log.
     * @param {None}
     * @returns {String} The title of audit log
     */
    auditLogTitle () {
      const action = this.activeAuditLog.action
      const recordType = this.activeAuditLog.record_type
      const recordId = this.activeAuditLog.record_id
      const details = this.activeAuditLog.details
      // From RecordTitleMixin
      return this.recordTitle(action, recordType, recordId, details)
    }
  },
  methods: {
    ...mapActions('audit', [
      'getAuditLog',
      'clearModalError'
    ]),
    /**
     * @public
     * Emit an event that closes the audit log.
     * @param {None}
     * @return {None}
     */
    closeAuditLog () {
      /**
       * Event that indicates if the audit log being viewed is closed.
       * @property {None}
       */
      this.$emit('close_audit_log')
    },
    /**
     * @public
     * @async
     * Retrieve audit log from backend if it is not previously cached.
     * Else, retrieve it from the cache in Vuex store.
     * @param {None}
     * @returns {None}
     */
    async retrieveAuditLog () {
      this.clearModalError()
      const foundAuditLog = this.isAuditLogCached()
      if (foundAuditLog) {
        this.activeAuditLog = foundAuditLog
      } else {
        this.activeAuditLog = await this.getAuditLog(this.activeAuditLogId)
      }
    },
    /**
     * @public
     * Finds an audit log in cache by activeAuditLogId.
     * @param {None}
     * @returns {Object | undefined} If audit log is found, returns it. Else, returns undefined.
     */
    isAuditLogCached () {
      const result = this.auditLogCache.find(auditLog => auditLog.id === this.activeAuditLogId)
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.detail {
  white-space: pre-wrap
}
</style>
