export default {
  data: function () {
    return {
      /**
       * Maps the action of an audit log to its appropriate past tense.
       */
      pastTenseMap: {
        search: this.$AUDIT('RECORD_TITLE').SEARCHED,
        run: this.$AUDIT('RECORD_TITLE').RAN,
        update: this.$AUDIT('RECORD_TITLE').UPDATED,
        read: this.$AUDIT('RECORD_TITLE').READ,
        delete: this.$AUDIT('RECORD_TITLE').DELETED,
        create: this.$AUDIT('RECORD_TITLE').CREATED,
        add: this.$AUDIT('RECORD_TITLE').ADD,
        remove: this.$AUDIT('RECORD_TITLE').REMOVE,
        'log in': this.$AUDIT('RECORD_TITLE').LOGGED_IN,
        'log out': this.$AUDIT('RECORD_TITLE').LOGGED_OUT,
        'add relations': this.$AUDIT('RECORD_TITLE').ADDED_RELATIONS_TO,
        'remove relations': this.$AUDIT('RECORD_TITLE').REMOVED_RELATIONS_FROM,
        'verify otp': this.$AUDIT('RECORD_TITLE').OTP_VERIFIED
      }
    }
  },
  methods: {
    /**
     * Dynamically returns the record title of an audit log.
     * @param {String} action The action that created the audit log
     * @param {String} recordType The record type associated to the audit log
     * @param {String} recordId The record Id associated to the audit log
     */
    recordTitle (action, recordType, recordId, details) {
      const actionText = this.pastTenseMap[action.toLowerCase()] || this.getActionTextForRelationsField(action)
      const title = `${actionText} ${recordType}`
      if (recordId) {
        return `${title} - ${recordId}`
      } else if (recordType === 'Insight Search') {
        const searchTerm = details.split('\n')[0].replace('Search Term: ', '')
        return `${recordType} (${searchTerm})`
      } else {
        return title
      }
    },
    /**
     * Return the action text for many-to-many relationship field that is not included in pastTenseMap
     * @param {String} action The action that created the audit log
     */
    getActionTextForRelationsField (action) {
      const actionWords = action.split(' ')
      actionWords[0] = this.pastTenseMap[actionWords[0].toLowerCase()]
      const actionText = actionWords.join(' ')
      return `${actionText} to`
    }
  }
}
