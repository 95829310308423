<template>
  <div>
    <b-modal
      :title="$AUDIT('SAVE_MODAL').TITLE"
      :visible="showSaveModal"
      @hide="closeSaveModal"
      @show="resetFilename"
      footer-class="modal-footer"
    >
      <label for="file_name">{{ $AUDIT('SAVE_MODAL').INPUT.LABEL }}</label>
      <b-input-group :append="$AUDIT('SAVE_MODAL').INPUT.FILE_EXTENSION">
        <b-form-input
          id="file_name"
          v-model="fileName"
          type="text"
          autofocus
        ></b-form-input>
      </b-input-group>
      <error-handler
        v-if="modalError !== false"
        :error="modalError"
        :showError="modalError !== false"
        variant="danger"
        @dismiss-error="clearModalError"
      />
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="openConfirmationModal" :disabled="savingIsHappening || invalidFileName">
          <b-spinner small v-if="isSavingAndDeleting"></b-spinner>
          <span v-else>{{ $AUDIT('SAVE_MODAL').ACTION.SAVE_AND_DELETE }}</span>
        </b-button>
        <b-button variant="primary" @click="exportAsCSV(false)" :disabled="savingIsHappening || invalidFileName">
          <b-spinner small v-if="isSaving"></b-spinner>
          <span v-else>{{ $AUDIT('SAVE_MODAL').ACTION.SAVE }}</span>
        </b-button>
     </template>
    </b-modal>

    <!-- Confirmation Modal. Yes to Save and Delete, No to cancel -->
    <b-modal
      :title="$AUDIT('SAVE_MODAL').CONFIRMATION.TITLE"
      v-model="showConfirmationModal"
      ok-variant="success"
      cancel-variant="danger"
      @ok="closeConfirmationModalAndExport"
      @hide="clearConfirmationText"
      :cancel-title="$LOCAL('COMMON_WORD').CANCEL"
    >
      <div class="modal-class">
        <p><WarningIcon /></p>
        <p>
          {{ $AUDIT('SAVE_MODAL').CONFIRMATION.WARNING_PART_1 }}
          <strong>{{ $AUDIT('SAVE_MODAL').CONFIRMATION.WARNING_PART_2 }}</strong>
          {{ $AUDIT('SAVE_MODAL').CONFIRMATION.WARNING_PART_3 }}
        </p>
      </div>
      <input class="w-100" v-model="confirmationText" :placeholder="$LOCAL('CONFIRMATION_PLACEHOLDER')">
      <template v-slot:modal-ok>
        <b-spinner small v-if="isSaving"></b-spinner>
        <span v-else>{{ $LOCAL('COMMON_WORD').CONFIRM }}</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import utils from '../utils'
import ToastMessage from '@/utils/toast_message'
import WarningIcon from '@/assets/la-warning-triangle.svg'
import ErrorHandler from '../../insight/components/ErrorHandler'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'save-modal',
  components: {
    ErrorHandler,
    WarningIcon
  },
  props: {
    /**
     * Indicates if the save modal needs to be shown.
     */
    showSaveModal: {
      type: Boolean,
      default: false
    },
    /**
     * Stores the last filter executed by user.
     */
    lastExecutedFilter: {
      type: [Object],
      required: true
    }
  },
  data: () => ({
    /**
     * Check if the "Delete Records After Download" modal is shown.
     */
    showConfirmationModal: false,
    /**
     * Check if download is ongoing.
     */
    isSaving: false,
    /**
     * Check if download with purge is ongoing.
     */
    isSavingAndDeleting: false,
    /**
     * The name of the file to be downloaded.
     */
    fileName: '',
    /**
     * Confirmation text typed by user before confirming the intention to delete records after download.
     */
    confirmationText: ''
  }),
  computed: {
    ...mapState('audit', {
      modalError: 'modalError'
    }),
    /**
     * Indicates if the file name given by user is invalid.
     * @param {None}
     * @returns {Boolean} true if invalid, false if valid
     */
    invalidFileName () {
      return this.fileName.trim() === ''
    },
    /**
     * Indicates if the download process is happening.
     * A download process can be save with delete or save without delete.
     * @param {None}
     * @returns {Boolean} true if downloading, false if not downloading
     */
    savingIsHappening () {
      return this.isSaving || this.isSavingAndDeleting
    }
  },
  methods: {
    ...mapActions('audit', [
      'downloadFilterResultsAsCSV',
      'clearModalError'
    ]),
    /**
     * @public
     * Close confirmation modal and:
     * 1. Download the CSV file if confirmation text is valid, or
     * 2. Show a toast error message if confirmation text is invalid
     * @param {None}
     * @returns {None}
     */
    closeConfirmationModalAndExport () {
      if (this.confirmationText !== this.$LOCAL('COMMON_WORD').YES) {
        ToastMessage.showErrorDefault({ vueInstance: this, textMessage: this.$LOCAL('CONFIRMATION_ERROR_MESSAGE') })
      } else {
        // Don't need to await. Close modal and allow download to happen.
        this.exportAsCSV(true)
      }
      // Close confirmation modal
      this.showConfirmationModal = false
    },
    /**
     * @public
     * Opens confirmation modal.
     * @param {None}
     * @returns {None}
     */
    openConfirmationModal () {
      this.showConfirmationModal = true
    },
    /**
     * @public
     * Emits event to close confirmation modal.
     * @param {None}
     * @returns {None}
     */
    closeSaveModal () {
      /**
       * Event that indicates the save modal is to be closed.
       * @property {None}
       */
      this.$emit('close_save_modal')
    },
    /**
     * @public
     * @async
     * Download the filter results as a CSV file.
     * @param {Boolean} purge Indicates if records are to be deleted after download. true to delete, false to not delete
     * @return {None}
     */
    async exportAsCSV (purge) {
      // Micro-control which loading spinner to show
      if (purge) {
        this.isSavingAndDeleting = true
      } else {
        this.isSaving = true
      }

      const filterParams = { ...this.lastExecutedFilter, purge: purge }

      // Retrieve CSV response
      const csv = await this.downloadFilterResultsAsCSV(filterParams)

      // Only download when there is no error
      if (this.modalError === false) {
        const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(csvData)
        link.setAttribute('download', this.fileName.trim() + this.$AUDIT('SAVE_MODAL').INPUT.FILE_EXTENSION)
        // Proceed to download the CSV file
        link.click()
        setTimeout(() => {
          // Click event is asynchronouse (gets added to the event loop)
          // Only remove the url after the current JS execution cycle is done
          window.URL.revokeObjectURL(link)
        }, 0)
        link.remove()

        this.closeSaveModal()

        // Refresh to clear results page
        if (purge) {
          /**
           * Event that indicates a refresh is triggered.
           * @property {None}
           */
          this.$emit('refresh')
        }
      }

      this.isSaving = false
      this.isSavingAndDeleting = false
    },
    /**
     * @public
     * Reset file name to the default format.
     * The default format is 'AuditLogs_<today's datetime>'.
     * @param {None}
     * @return {None}
     */
    resetFilename () {
      this.fileName = this.$AUDIT('INDEX').DEFAULT_FILENAME_PREFIX + `${utils.formatDateTimeFileName(new Date())}`
    },
    /**
     * @public
     * Clear confirmation text.
     * @param {None}
     * @return {None}
     */
    clearConfirmationText () {
      this.confirmationText = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-class {
  display: flex;
  align-items: center;
}
</style>
