<template>
  <div>
    <portal to="top-bar">
      <div class="title">
        <h1>{{ $AUDIT('INDEX').TITLE }}</h1>
      </div>
      <div v-if="isSearchExecuted && !isLoading">
        <b-button
          size="sm"
          variant="outline-secondary"
          :disabled="filterResults.length === 0"
          @click="showSaveModal = !showSaveModal"
        >
          {{ $LOCAL('COMMON_WORD').EXPORT }}
        </b-button>
      </div>
    </portal>

    <!-- Main page spinner -->
    <b-spinner v-if="!isInitialised && isLoading"></b-spinner>

    <section v-if="isInitialised">
      <!-- The FilterControl component -->
      <filter-controls
        @search-executed="fetchFilterResults"
        @refresh="refresh"
        :lastExecutedFilter="lastExecutedFilter"
        :totalNumberOfResults="totalNumberOfResults"
      />
    </section>

    <!-- Error handler component from Insight module -->
    <error-handler
      v-if="error !== false"
      :error="error"
      :showError="error !== false"
      variant="danger"
      @dismiss-error="clearError"
    />

    <!-- Display filter results -->
    <section class="results-area" v-if="isSearchExecuted">
      <index-cards :items="filterResults" :hiddenFields="ignoredFields">
        <template v-slot:name="{ item }">
          <!-- From RecordTitleMixin -->
          {{ recordTitle(item.action, item.record_type, item.record_id, item.details) }}
        </template>
        <template v-slot:view="{ item }">
          <b-btn variant="primary" v-b-tooltip:results-area.hover :title="$LOCAL('ToolTipLabelView')">
            <LaEye height="20px" @click="openAuditLog(item.id)"/>
          </b-btn>
        </template>
      </index-cards>
    </section>
    <footer class="load-more" v-if="isInitialised && totalNumberOfResults > filterResults.length">
      <b-button variant="outline" @click="loadMore">
        <b-badge pill variant="secondary" v-if="!isLoading">{{ $LOCAL('LoadMore') }}</b-badge>
        <b-spinner small v-if="isLoading" label="Loading..."/>
      </b-button>
    </footer>

    <!-- Individual log records to see Details and Changes -->
    <audit-log
      v-if="showAuditLog"
      :activeAuditLogId="activeAuditLogId"
      :showAuditLog="showAuditLog"
      @close_audit_log="closeAuditLog"
    />

    <!-- Save Modal to allow user export logs as CSV -->
    <save-modal
      v-if="showSaveModal"
      :showSaveModal="showSaveModal"
      :lastExecutedFilter="lastExecutedFilter"
      @close_save_modal="closeSaveModal"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import LaEye from '@/assets/la-eye.svg'
import FilterControls from '../components/FilterControls'
import IndexCards from '@/modules/insight/components/IndexCards'
import AuditLog from '../components/AuditLog'
import RecordTitleMixin from '../mixins/RecordTitleMixin'
import SaveModal from '../components/SaveModal'
import ErrorHandler from '../../insight/components/ErrorHandler'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'audit-index',
  components: {
    FilterControls,
    IndexCards,
    AuditLog,
    LaEye,
    ErrorHandler,
    SaveModal
  },
  props: {

  },
  mixins: [RecordTitleMixin],
  data: () => ({
    page: 2, // For pagination
    isSearchExecuted: false, // Check if a search filter is executed by user
    showAuditLog: false, // Check if an audit log is viewed by user
    showSaveModal: false, // Check if the "Save As" modal is shown
    activeAuditLogId: '', // Contains the Id of the audit log viewed by user
    lastExecutedFilter: {} // Stores the last executed filter (for pagination and download)
  }),
  computed: {
    ...mapState('audit', {
      users: 'users',
      isLoading: 'isLoading',
      isInitialised: 'isInitialised',
      error: 'error',
      filterResults: 'filterResults',
      totalNumberOfResults: 'totalNumberOfResults'
    }),
    /**
     * Returns the number of search result.
     * @param {None}
     * @returns {String} Empty string if search is not yet executed. Else, show the number of results
     */
    searchNote () {
      if (this.isSearchExecuted) {
        return `Displaying ${this.totalNumberOfResults} Result(s)`
      }
      return ''
    },
    /**
     * Fields of an audit log to be ignored in an index-card.
     * @param {None}
     * @returns {Array} An array of fields that are ignored
     */
    ignoredFields () {
      return ['id', 'changes', 'details']
    }
  },
  /**
   * Fetch the list of users and the list of registered Django models to be audited.
   * Multiple API calls are chained in fetchDataOnMount. When one fails, the others won't proceed and an error will be thrown.
   * When there is an error, Audit won't be initialised. The page will be empty.
   */
  async mounted () {
    await this.fetchDataOnMount()
  },
  /**
   * Clear filter results from memory when user moves away from audit page.
   */
  beforeDestroy () {
    this.cleanUpComponent()
  },
  methods: {
    ...mapActions('audit', [
      'getFilterResults',
      'cleanUpComponent',
      'fetchDataOnMount',
      'clearError'
    ]),
    /**
     * @public
     * @async
     * Fetch the first page of filter results. Limited to 25 results per page.
     * @param {Object} filterParams An object of filter parameters to fetch the results
     * @returns {None}
     */
    async fetchFilterResults (filterParams) {
      this.page = 1 // Resets the number of page
      this.isSearchExecuted = true
      this.lastExecutedFilter = filterParams // Saves filterParams as the last executed filter
      filterParams.page = this.page
      await this.getFilterResults({ filterParams, append: false })
    },
    /**
     * @public
     * @async
     * Retrieves paginated data on the 'next page'.
     * @param {None}
     * @returns {None}
     */
    async loadMore () {
      this.page++ // Next page
      const filterParams = { ...this.lastExecutedFilter, page: this.page }
      await this.getFilterResults({ filterParams, append: true }) // Append new results
    },
    /**
     * @public
     * Opens a specific audit log given its Id.
     * @param {String} logId The Id of audit log viewed by user
     * @return {None}
     */
    openAuditLog (logId) {
      this.showAuditLog = true
      this.activeAuditLogId = logId
    },
    /**
     * @public
     * Closes an audit log viewed by user.
     * @param {None}
     * @return {None}
     */
    closeAuditLog () {
      this.showAuditLog = false
      this.activeAuditLogId = ''
    },
    /**
     * @public
     * Closes the download page.
     * @param {None}
     * @returns {None}
     */
    closeSaveModal () {
      this.showSaveModal = false
    },
    /**
     * @public
     * @async
     * Refreshes the results page based on the last executed filter.
     * Also used to clear the page when filter results are deleted after download.
     * @param {None}
     * @returns {None}
     */
    async refresh () {
      await this.fetchFilterResults(this.lastExecutedFilter)
    }
  }
}
</script>

<style lang="scss" scoped>
.results-area {
  margin-top: -15px;
  margin-bottom: 15px;
}

</style>
